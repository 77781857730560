import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "copy-flume-config"
    }}>{`Copy Flume Config`}</h1>
    <p>{`Our advanced integrations use a library called `}<a parentName="p" {...{
        "href": "https://flume.dev"
      }}>{`flume`}</a>{`. Users can define their integrations on the client in the form of node graphs and we evaluate those graphs on the server. This requires us to have the same node/port config on both the client and the server.`}</p>
    <p>{`The client config is found in `}<inlineCode parentName="p">{`app/src/flume/config.jsx`}</inlineCode>{` and is the canonical source. Running this script will copy the necessary parts over to the server where the config is located at `}<inlineCode parentName="p">{`server/resources/advanced-integrations/flume/config.js`}</inlineCode>{`.`}</p>
    <p>{`This script is very basic. It only copies over the four chunks of code defined between the two comment blocks, namely: `}<inlineCode parentName="p">{`ports`}</inlineCode>{`, `}<inlineCode parentName="p">{`nodes`}</inlineCode>{`, `}<inlineCode parentName="p">{`prepare`}</inlineCode>{`, `}<inlineCode parentName="p">{`advancedPorts`}</inlineCode>{`. For `}<inlineCode parentName="p">{`ports`}</inlineCode>{`, we only copy over the keys `}<inlineCode parentName="p">{`isEmpty`}</inlineCode>{` and `}<inlineCode parentName="p">{`resolve`}</inlineCode>{`. For `}<inlineCode parentName="p">{`nodes`}</inlineCode>{`, we copy over the keys `}<inlineCode parentName="p">{`inputs`}</inlineCode>{`, `}<inlineCode parentName="p">{`outputs`}</inlineCode>{`, `}<inlineCode parentName="p">{`resolve`}</inlineCode>{`, and `}<inlineCode parentName="p">{`gadget`}</inlineCode>{`. For the other two we copy it verbatim.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`node scripts/copy-flume-config ../app-builder-api/server/resources/advanced-integrations/flume/config.js
`}</code></pre>
    <h2 {...{
      "id": "caveats"
    }}>{`Caveats`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Because we only copy the four declarations described above (and because we do it very crudely) don't rename them or change how they're defined or abstract them out into their own files or create new root level declarations that need copied over. If you do any of that you'll have to update this script.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you add new keys to `}<inlineCode parentName="p">{`ports`}</inlineCode>{` or `}<inlineCode parentName="p">{`nodes`}</inlineCode>{` that need to be on the server, make sure to update this script.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you add and use a new node_module, chances are you'll also need to make sure it is installed on the server. You'll also need to update this script to import it.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you rely on browser built-ins, you'll need to install a polyfill on the server and update the script.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Make sure to test any nodes you're adding or updating. The `}<inlineCode parentName="p">{`resolve`}</inlineCode>{` code is only evaluated when testing/running the code on the server.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      